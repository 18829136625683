<template>
    <!-- 确认商品 -->
    <div class="confirm-wrap grey-bg fh">
        <!--地址选择开始-->
        <div class="address-wrap">
            <div class="address-box vertical-center">
                <img src="~assets/images/public/location-icon.png" class="location-icon"/>
                <div class="location-text-box">
                    <div class="location-user vertical-center">
                        <span class="name white-space">{{commodityInfo.contact_name}}</span>
                        <span class="tell">{{commodityInfo.contact_mobile}}</span>
                    </div>
                    <div class="location-text white-space-2">{{ commodityInfo.address }}</div>
                </div>
            </div>
        </div>
        <!--地址选择结束-->
        <!--商品开始-->
        <div class="commodity-wrap">
            <div class="commodity-tit">商品信息</div>
            <div class="commodity-info vertical-end" @click="commodityLink">
                <img :src="commodityInfo.flea && commodityInfo.flea.picture[0]" class="commodity-img"/>
                <div class="commodity-detail">
                    <div class="title white-space-2">{{commodityInfo.flea && commodityInfo.flea.title}}</div>
                    <div class="integral-num">{{commodityInfo.credit}}积分</div>
                </div>
            </div>
        </div>
        <!--商品结束-->

        <!--统计开始-->
   <div class="commodity-wrap">
        <div class="commodity-tit">快递信息</div>
        <van-cell title="快递公司" is-link :value="exp_name_value" @click="goInformation()" class="kd" />
        <van-cell title="快递单号" >
            <input v-model="exp_number" placeholder="请输入快递单号" border="{{ false }}" class="create-input"/>
        </van-cell>

    </div>
        <!--统计结束-->
        <div class="confirm-btn-box">
          <div class="confirm-btn theme-bg" @click="confirmFUn">提交</div>
          <div class="safety-height"></div>
        </div>
    </div>
  <van-toast id="van-toast" />
    <van-action-sheet v-model:show="Informationshow" :actions="Information" cancel-text="取消"  description="选择物流" close-on-click-action @cancel="InformationClose" @select="InformationSelect" />
</template>

<script>
import _ from 'lodash'
export default {
  name: "orderConfirm",
  data(){
    return{
      id: '',
      commodityInfo: {},
      address_id: '',
      addressInfo: null,
      submit_status: true,
      type:'',
        Information:[],
        Informationshow:false,
        exp_name:'',
        exp_number:'',
        exp_name_value:'请选择',
        exp_type:'',
        subbtn:true,
    }
  },
  created() {
    if(this.$route.query.id){
      this.id = this.$route.query.id
    }
    if(this.$route.query.type){
      this.type = this.$route.query.type
    }
     // 获取商品详情
      this.getCommodity();
      this.getInformation()
  },
  mounted() {
    this.$nextTick(() => {
      this.$util.domMinHeight()
    })
  },
  methods: {
      //物流
    getInformation(){
        let url = this.$api.logisticsInformation 
       this.$http.get(url,true).then( res => {
            if(res.data.success){
              this.Information= res.data.data
            }
          })
    },
       //  获取商品详情
    getCommodity() {
        if(this.type == 'flea'){
            let url = this.$api.communityFleaOrder + '/' + this.id
           this.$http.get(url,true).then( res => {
              if(res.data.success){
                this.commodityInfo = res.data.data;
                this.exp_name_value = res.data.data.exp_name
                this.exp_name = res.data.data.exp_type
                this.exp_number = res.data.data.exp_number
                this.exp_type = res.data.data.exp_type
                if(res.data.data.exp_name == '' || res.data.data.exp_name == null){
                  this.exp_name_value = '请选择'
                }
              }
              })
        }
    },
     goInformation(){
        this.Informationshow = true
    },
    InformationClose() {
        this.Informationshow = false 
      },
    
      InformationSelect(event) {
          
        this.exp_name= event.type
        this.exp_name_value = event.name
        this.exp_type=event.type
        
      },
  //  地址跳转
    addressLink(){
      this.$router.push({path: '/user/address',query: {
          f: this.$route.query.f
        }})
    },
  //  获取地址详情
    getAddressInfo(id){
      let url = this.$api.Address + '/' + id;
      this.$http.get(url,true).then( res =>{
        if(res.data.success){
            if(res.data.data.constructor == Object){
                this.addressInfo = res.data.data;

            }
        else{
                this.setData({
                    addressInfo: null
                })
            }
        }
      })
    },
    // 确认兑换
     confirmFUn: _.debounce(function () {
       if(!this.subbtn){
         return
       }
        if(this.exp_number == '' || this.exp_number == null){
                this.$toast('请填写快递单号');
                return
            }
            if(this.exp_type == '' || this.exp_name == '' || this.exp_type == null || this.exp_name == null){
                this.$toast('请选择快递公司');
                return
            }
        let url
        if(this.commodityInfo.status == 2){
            url =  this.$api.communityFleaupdate
        }else{
            url =  this.$api.communityFleaShip
        }
        let data = {
            id:this.commodityInfo.id,
            exp_name:this.exp_name,
            exp_number:this.exp_number,
            exp_type:this.exp_type
        }
        this.$http.put(url,data,true).then( res =>{
            if (res.data.success) {
              this.$router.replace('/user/record')
            }else{
              this.subbtn = false
              this.$toast(res.data.message);
               setTimeout(() => {
            this.$router.go(-1)
          }, 3000);
            }
        }).catch(error =>{
            this.subbtn = false
           setTimeout(() => {
            this.$router.go(-1)
          }, 3000);
            if (error.response.status == 422) {
          this.$toast(error.response.data.message);
        }
        })
    }, 500),
    //  获取地址列表
    getAddressList(){
      let url = this.$api.Address;
      this.$http.get(url,true).then( res => {
        if(res.data.success){
          if(res.data.data.data.length){
            let info = res.data.data.data;
            for (let i=0; i<info.length;i++){
              if(info[i].is_default){
                this.addressInfo = info[i];
                this.address_id = info[i].id
              }
            }
            if(this.addressInfo == null){
              this.addressInfo = info[0];
              this.address_id = info[0].id
            }
          }
        }
      })
    },

    // 查看商品详情
    commodityLink(){
        this.$router.push({path: '/community/market-show' ,query: {id: this.id}})
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/order/confirm.scss";
</style>
<style lang="scss">
$r: 750/16/1rem;
input{
    border: none;
}

.fh .van-cell{
  padding: 20/$r 0;
  border-bottom: 1px solid #F0F2F4;
  .van-cell__title{
    width: 150/$r;
    flex: unset;
  }
  .van-cell__value{
text-align: left;
  }
}
.fh .van-cell:after{
  border: unset;
}
</style>